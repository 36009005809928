/**
 * Created by wangqian on 18/6/21.
 */
// 本地测试服务器
// let localHost = 'http://47.93.28.5:8787'
// let localHost = 'http://192.168.100.217:9999'
let localHost = 'https://api.gcw.net:9494'
// let wapHref = 'http://m.gcw.net'
// 'https://admin.gcw.net'
// 生产服务器
// let aliyunHost = 'http://192.168.100.217:9999'
let aliyunHost = 'https://api.gcw.net:9494'
let wapHref = 'https://m.jingyingbang.com'
// 'http://116.62.126.0:9696'
// 'http://192.168.101.16:8686'
// 'https://admin.gcw.net'
export const wapUrl = wapHref
export const HOST = (process.env.NODE_ENV === 'production') ? aliyunHost : localHost

const TIMEOUT = 50000000
const sysBaseConf = {
  'development': {
    company: '经营帮科技有限公司',
    icp: '陕ICP备2022005064号',
    supermarkeComponList: ['201909024695838'], // 配置经营帮超市  企业数据
    supermarkeCompanyId: 807, // 配置经营帮超市  企业数据
    companyNoList:[202007211619262, 1321724009720713216, 201907185069098, 201909024695838], //首页顶部菜单栏展示 吃住行 商机服务 "深圳市睿水达壳黄有有限公司" "松下家电" '新疆金正大工贸有限公司' '中钧科技有限公司'
    topMenuList:[
    {
      name:"食住行",
      pcUrl:'https://trip.gcw.net/' // https://trip.jingyingbang.net/index  https://trip.jingyingbang.com/index
    }]
  },
  'pre': { // 预发
    company: '经营帮科技有限公司',
    icp: '陕ICP备2022005064号',
    supermarkeComponList: ['202004302544834'],
    supermarkeCompanyId: 2891, 
    companyNoList:[1316637963131686912, 202004302544834], //首页顶部菜单栏展示 吃住行 商机服务 "西安互联网科技有限公司(设备)"  "中钧科技有限公司"
    topMenuList:[
    {
      name:"食住行",
      pcUrl:'https://trip.jingyingbang.net/'
    }]
  },
  'production': { //生产
    company: '经营帮科技有限公司',
    icp: '陕ICP备2022005064号',
    supermarkeComponList: ['1658743593847885824'],
    supermarkeCompanyId: 213593, 
    companyNoList:[202008148048453, 202004302544834], //首页顶部菜单栏展示 吃住行 商机服务  陕西经营帮 中钧科技
    topMenuList:[
    {
      name:"食住行",
      pcUrl:'https://trip.jingyingbang.com/'
    }]
  }
}
export const AppConfig = {
  host: HOST,
  timeout: TIMEOUT,
  wapUrl,
  sysBaseConf:sysBaseConf[process.env.environment],
}
