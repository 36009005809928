//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import { AppConfig } from "@/config";
import env from '../../../env'
export default {
  name: "Menu",
  data() {
    return {
      bg: 0,
      menuList: [],
      checked: "工程机械",
      mechanical: "工程材料",
      management: "企业管理",
      dialogVisible: false
    };
  },
  async asyncData() {
    
    let res = await apiHome.getMenuList();
      menuList = res.data;
    return {
      menuList
    };
  },
  methods: {
    linkGo(item) {
      // if(item.name == '企业管理'){
      //   this.$router.push({path: '/engineeringManagement'})
      //   return
      // }
      if (item.name == "工程材料") {
        this.$router.push({ path: "/index" });
        return;
      }
      if (item.pcUrl) {
        // if(item.no === "qygl"){
        //   if (!this.$store.state.loginType) {
        //     this.loginAuth()
        //     return false
        //   }else if (!this.$store.state.jurisdiction.addCar) {
        //     this.dialogVisible = true
        //     return false
        //   }
        // }
        if (item.self) {
          window.location.href = item.pcUrl;
        } else {
          window.open(item.pcUrl, "_blank");
        }
      }
    },
    loginAuth() {
      let data = {
        callBack: function() {
          this.$store.dispatch("setUser");
        }.bind(this)
      };
      this.$_login(data);
    },
    // 跳认证页面
    goToAuthPage() {
      window.location.href = env[process.env.environment].VUE_APP_PERSONURL + "/authenticationManagement";
    },
    async getMenuList() {
      let res = await apiHome.getMenuList();
      this.menuList = res.data;
    },
    changeBg() {
      let route = this.$route.name;
      if (route == "EngineeringManagement") {
        this.checked = this.management;
      } else {
        this.checked = this.mechanical;
      }
    }
  },
  created() {
    this.getMenuList();
    this.changeBg();
  }
};
