//
//
//
//
//
//

import Userinfo from './components/RedesignUserinfo';
import Search from './components/Search';
export default {
  name: 'Header',
  data () {
    return {
      isChecked: true,
      query: ''
    }
  },
  // props: ['flag', 'searchs'],
  components: {
    // Userinfo: () => import('./components/Userinfo'),
    Userinfo,
    Search
  },
  methods: {},
  watch: {},
  mounted () {},
  created () {}
}
