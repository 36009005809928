//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import localStorage from "@/storage/localStorage";
import { apiHome } from "@/api/apiHome";
import apiSearch from "@/api/apiSearch";
import EventBus from "@/util/event-bus";
import { Console } from 'console';
var env = require("../../../../env");
function throttle(fn, wait = 500, isImmediate = false) {
  let flag = true;
  if (isImmediate) {
    return function() {
      if (flag) {
        fn.apply(this, arguments);
        flag = false;
        setTimeout(() => {
          flag = true;
        }, wait);
      }
    };
  }
  return function() {
    if (flag == true) {
      flag = false;
      setTimeout(() => {
        fn.apply(this, arguments);
        flag = true;
      }, wait);
    }
  };
}
function debounce(fn, wait = 500, isImmediate = false) {
  let timerId = null;
  let flag = true;
  if (isImmediate) {
    return function() {
      clearTimeout(timerId);
      if (flag) {
        fn.apply(this, arguments);
        flag = false;
      }
      timerId = setTimeout(() => {
        flag = true;
      }, wait);
    };
  }
  return function() {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      fn.apply(this, arguments);
    }, wait);
  };
}

export default {
  name: "SubmitSearch",
  mixins: [],
  extends: {},
  props: {
    btnIsshow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      initPlaceholder: "无输入关键字",
      placeholder: "",
      query: "",
      searchList: [],
      num: "",
      searchStyleList: [],
      hotKey: [],
      showRecommend: false,
      recentlySearchList: [], // 最近搜索记录
      hoverIndex: null,
      isSearchGlobal: false,
      isOnfouch: false,
      globalList: [],
      selectIndex: "",
      isChecked: false,
      Probe: null,
      probeConfig: {
        plateform: "pc",
        module: "home",
        url: `${
          env[process.env.environment].VUE_APP_BURYINGPOINT
        }/v3/behaviout/log`,
        source: "jyb"
      },
      url_usercenter:env[process.env.environment].VUE_APP_PERSONURL
    };
  },
  computed: {
    userInfo: function() {
      return this.$store.state.user;
    },
    authUser() {
      return this.$store.state.authUser;
    },
    btnPermissions() {
      if (process.client) {
        return (
          this.$route.path.indexOf("/Inquiry") === -1 &&
          this.$route.path.indexOf("/SearchResult") === -1 &&
          this.$route.path.indexOf("/content") === -1
        );
      } else {
        return false;
      }
    },
    seachKey() {
      return this.$store.state.searchKeyword;
    }
    // isShowPop() {
    //   return this.recentlySearchList.length > 0
    // }
  },
  components: {},
  filter: {},
  watch: {
    isOnfouch: function(oldv, newv) {
      console.log(this.isOnfouch);
    },
    seachKey: {
      immediate: true,
      handler(newVal, oldVal) {
        this.query = newVal;
      }
    }
    // seachKey(oldVal, newVal) {
    //   this.query = oldVal
    // },
  },
  inject: ["reload"],
  methods: {
    outBlur() {
      this.$refs["searchInner"].blur();
    },
    checkGsearch(value) {
      const { type, title } = value;
      this.query = title;
      this.$store.commit("SET_SEARCH_KEY", title);
      localStorage.set("search_query", this.query);
      this.recentlySearchList.unshift(this.query);
      this.setSearchList();
      this.isOnfouch = false;
      this.$refs["searchInner"].blur();
      console.log("this.$refs[searchInner]", this.$refs["searchInner"]);
      let path;
      console.log("path", type);
      switch (type) {
        case "qiye":
          path = "/SearchResult/companyList";
          break;
        case "shangpin":
          path = "/SearchResult/goodsList";
          break;
        case "zhaocai":
          path = "/SearchResult/tenderList";
          break;
        case "jicai":
          path = "/SearchResult/gropbuyList";
          break;
        case "zulin":
          path = "/SearchResult/leaseList";
          break;
        case "wuliu":
          path = "/SearchResult/logisticsList";
          break;
        default:
          break;
      }
      this.$router.push({
        path: path
      });
      if (this.query != "") {
        // this.Probe.search(this.query);
        try {
        Probe && new Probe(this.probeConfig).search(this.query);
        } catch (error) {
          
        }
      }
      this.$bus.$emit("submitSearch");
    },
    clearSearch() {
      if (!this.query) {
        return;
      }
      this.selectIndex = "";
      this.isOnfouch = false;
      this.globalList = [];
      this.query = "";
      this.$store.commit("SET_SEARCH_KEY", this.query);
      localStorage.set("search_query", this.query);
      this.$bus.$emit("submitSearch");
    },
    delSearch(data) {
      let searchArr = JSON.parse(localStorage.read("searchList")) || [];
      const index = searchArr.findIndex(item => item == data);
      searchArr = searchArr.filter((item, i) => i !== index);
      localStorage.set("searchList", JSON.stringify(searchArr));
      this.recentlySearchList = searchArr;
    },
    //点击询价按钮
    clickEnquiryBtn() {
      if (!this.$store.state.loginType) {
        let data = {
          callBack: function() {
            this.$store.dispatch("setUser");
            // this.pushThis();
          }.bind(this)
        };
        this.$_login(data);
        return;
      }
      // window.open(`${this.url_usercenter}/SaveInquiryPrice`)
      this.$bus.$emit('xunjiaDlo', {isOpen: true})
    },
    //点击发布按钮
    clickReleaseBtn() {
      this.$bus.$emit("clickSearchReleaseBtn");
    },
    clickCustomerBtn(){
      this.$bus.$emit("kfClient")
    },
    spliceSearchList(item) {
      let string = item;
      return string;
    },
    async getSearchGlobalList(value) {
      this.isChecked = false;
      if (!value) {
        this.globalList = [];
        return;
      }
      try {
        let res = await apiHome.getSearchGlobalList({
          keyword: value
          // type: "",
        });
        console.log("res", res);
        this.globalList = res.data.data || [];
      } catch (error) {
        this.globalList = [];
      }
    },
    inputa: throttle(
      function(value) {
        this.getSearchGlobalList(value);
      },
      200,
      false
    ),
    inputb: debounce(
      function(value) {
        this.getSearchGlobalList(value);
      },
      300,
      false
    ),
    async input(value) {
      // this.inputa(this.query);
      this.inputb(this.query);
      this.selectIndex = "";
      this.searchList = [];
      this.$store.commit("SET_SEARCH_KEY", this.query);
      localStorage.set("search_query", this.query);
      if (this.query == "") {
        this.showRecommend = true;
        this.getSearchList();
      } else {
        this.showRecommend = false;
      }
      // if (this.query.length > 50) {
      //   this.query = this.query.slice(0, 50);
      // }
    },
    focus() {
      // 获取焦点
      this.getHotKey();
      this.globalList = [];
      this.selectIndex = "";
      this.isOnfouch = true;
      if (!this.query) {
        this.showRecommend = true;
        this.getSearchList();
      } else {
        this.showRecommend = false;
        //  this.inputa(this.query);
        this.inputb(this.query);
      }
    },
    onBlur() {
      let that = this;
      setTimeout(function() {
        that.searchList = [];
        that.showRecommend = false;
      }, 500);
      setTimeout(() => {
        this.isOnfouch = false;
      }, 300);
    },
    getSearchList() {
      this.recentlySearchList = [];
      let List = localStorage.read("searchList");
      if (List == null) {
        List = [];
      } else {
        List = JSON.parse(List);
      }
      this.recentlySearchList = List;
    },
    deletedSearchList() {
      if (process.client) {
        localStorage.clear("searchList");
        this.getSearchList();
      }
    },
    setSearchList() {
      // 校验是否为空
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      let bol = re.test(this.query);
      if (this.query == "" || bol || !this.query) {
        return;
      }
      // 如果有历史搜索  则提前
      let index = this.recentlySearchList.indexOf(this.query);
      if (index !== -1) {
        this.recentlySearchList.splice(index, 1);
      }
      this.recentlySearchList.unshift(this.query);
      // 删除多余元素  只保留十个
      while (this.recentlySearchList.length > 10) {
        this.recentlySearchList.pop();
      }
      let List = JSON.stringify(this.recentlySearchList);
      localStorage.set("searchList", List);
    },
    selectStyle(index) {
      this.hoverIndex = index;
      this.changeStyle();
      this.searchStyleList.splice(index, 1, true);
    },
    async selectDown() {
      let key = window.event.keyCode;
      console.log(key, "key----");
      switch (key) {
        case 13:
          this.search();
          return;

        case 38:
          this.monitorOnkeydown(38);
          return;

        case 40:
          this.monitorOnkeydown(40);
          return;
        default:
          break;
      }

      let obj = {};
      obj.page = 1;
      obj.size = 10;
      obj.key = this.query;

      let res = await apiSearch.keywords(obj);
      this.hoverIndex = null;
      this.searchList = res.data.data;

      this.initSearchStyleList();
    },
    changeStyle() {
      // 初始化
      // hover清空
      for (let i = 0; i < this.searchStyleList.length; i++) {
        this.searchStyleList.splice(i, 1, false);
      }
    },
    initSearchStyleList() {
      // 初始化搜索选择列表
      this.searchStyleList = [];
      for (let i = 0; i < this.searchList.length; i++) {
        this.searchStyleList.push(false);
      }
    },
    async getHotKey() {
      // 热门搜索词
      let res = await apiHome.getReviewHotKey();
      const { data = [], code = 1, msg } = res.data.data && res.data;
      if (+code === 200) {
        this.hotKey = data || [];
      }
      if (process.client) {
        if (
          localStorage.read("search_query") != null &&
          this.$route.path != "/" &&
          this.$route.path != "/home"
        ) {
          this.query = localStorage.read("search_query");
          this.placeholder = localStorage.read("search_query");
          return;
        }
      }

      if (this.query == "") {
        // this.placeholder = this.hotKey[0];
      }
    },
    monitorOnkeydown(key) {
      // 设置键盘监听
      let num = 1;
      let index;
      switch (key) {
        case 38:
          num *= -1;
          break;
        case 40:
          num *= 1;
          break;
      }
      if (this.hoverIndex == null) {
        index = 0;
        if (num == 1) {
          this.query = this.searchList[index];
          this.selectStyle(0);
        }
        if (num == -1) {
          this.query = this.searchList[index];
          this.selectStyle(this.searchStyleList.length - 1);
        }
        return;
      } else {
        index = this.hoverIndex + num;
      }
      let max = this.searchStyleList.length;
      switch (index) {
        case -1:
          index = this.searchStyleList.length - 1;
          break;
        case max:
          index = 0;
          break;
        default:
          break;
      }

      this.query = this.searchList[index];
      this.selectStyle(index);
    },
    clickList(code, type) {
      // console.log("code:", code);
      // type=0是最近搜索词，type=1是热门搜索词
      if (type == 0) {
        this.query = code;
      } else if (type == 1) {
        this.query = code.word;
      }
      this.showRecommend = false;
      // if (this.query != "") {
      //   this.Probe.search(this.query);
      // }
      // this.query = code;
      this.searchList = [];
      this.search();
    },
    search() {
      console.log("search---");
      // if (process.client) {
      // debugger;
      this.$refs["searchInner"].blur();
      console.log(this.isOnfouch, "this.isOnfouch");
      console.log(this.selectIndex, "this.selectIndex");
      console.log(this.isChecked, "this.isChecked");
      if (this.isOnfouch && this.selectIndex != "" && this.isChecked) {
        console.log("111-----");
        this.checkGsearch(this.globalList[this.selectIndex]);
        return;
      }

      let localStorage1 = localStorage.read("search_query");
      this.isOnfouch = false;
      console.log(localStorage1, "localStorage1localStorage1");
      console.log(this.query, "this.query");
      if (this.query == "") {
        // console.log('222-----');
        if (localStorage1 == null) {
          // console.log('333-----');
          this.query = this.placeholder;
        } else {
          // console.log('444-----');
          this.query = localStorage1;
          if (this.$route.path == "/SearchResult") {
            // console.log('555-----');
            return;
          }
        }
      }
      if (this.query != "") {
        // this.Probe.search(this.query);
        try {
          Probe && new Probe(this.probeConfig).search(this.query);
        } catch (error) {
          console.log(error, "error")
        }
      }
      this.searchList = [];
      this.setSearchList();
      // let ss = this.regx(this.query);
      // if (ss) {
      localStorage.set("search_query", this.query ? this.query : "");
      if (this.$route.path.indexOf("/SearchResult") != -1) {
        // this.loggerSearchTerm()
        // this.$router.go(0);
        this.$bus.$emit("submitSearch");
      } else {
        console.log("777-----");
        this.$router.push({ path: "/SearchResult" });
        // this.loggerSearchTerm()
      }
      // }
      // }
    },
    regx(newName) {
      // var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
      //     regCn = /[·！#￥\s（——）：；“”‘、，|《。》？、【】[\]]/im;
      var regEn = /[`~!@$%^&*_+<>?:"{},\/;'[\]]/im;
      var regCn = /[·！￥——：；“”‘、，|《。》？、【】[\]]/im;
      if (regEn.test(newName) || regCn.test(newName)) {
        return false;
      } else {
        return true;
      }
    },
    // 埋点搜索词
    async loggerSearchTerm() {
      let value = await localStorage.read("search_query");
      await this.$loggerHub([
        {
          key: "searchTerms",
          value: value ? value : ""
        }
      ]);
    },
    searchDown(event) {
      // keyCode
      var key_num = event.keyCode;
      console.log(key_num);
      let gLength = this.globalList.length;
      if (!gLength) {
        return;
      }
      if (key_num === 38) {
        // 上
        if (this.selectIndex === "") {
          this.selectIndex = gLength - 1;
        } else if (this.selectIndex === 0) {
          this.selectIndex = gLength - 1;
        } else {
          this.selectIndex -= 1;
        }
      } else {
        if (this.selectIndex === "") {
          this.selectIndex = 0;
        } else if (this.selectIndex === gLength - 1) {
          this.selectIndex = 0;
        } else {
          this.selectIndex += 1;
        }
      }
      // localStorage.set("search_query", this.globalList[this.selectIndex]);
      this.query = this.globalList[this.selectIndex]["title"];
      this.isChecked = true;

      // console.log(key_num);
      // if (38 == key_num) {
      //   event.preventDefault();
      //   return false;
      // }
    },
    // searchUp
    initQuery() {
      this.query = localStorage.read("search_query");
    }
  },
  created() {
    // if (Object.keys(localStorage).length == 0) {
    //   return;
    // } else if (this.$route.path == "/" || this.$route.path == "/home") {
    //   this.query = "";
    // } else {
    //   this.query = localStorage.read("search_query");
    // }
  },

  mounted() {
    if (process.client) {
      this.initQuery();
      EventBus.$on("reload", () => {
        localStorage.clear("search_query");
        this.query = "";
      });
      this.getSearchList();
      // // 实例化埋点
      // let config = {
      //   plateform: "pc",
      //   module: "home",
      //   url: `${
      //     env[process.env.environment].VUE_APP_BURYINGPOINT
      //   }/v3/behaviout/log`,
      //   source: "jyb",
      // };
      // this.Probe = Probe ? new Probe(config) : null;
      // this.Probe = Probe ? new Probe(config) : null;
    }
  },
  beforeDestroy() {}
};
