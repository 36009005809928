//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import env from "@/../env.js";
import subMenu from "./subMenu.js";
import { AppConfig } from "@/config";
let exit = require("@/assets/exit.png");
export default {
  name: "Menu",
  data() {
    return {
      subMenu,
      activeMenu: 0,
      moverNav: false,
      exit,
      childlist: [],
      activeIndex: -1
    };
  },
  components: {
    moveNav: () => import("../components/movenav.vue")
  },
  props: {},
  computed: {
    menuList: function() {
      let menuList = JSON.parse(
        JSON.stringify(this.$store.state.IndexMenuList)
      );
      console.log('数据', menuList)
      return menuList
      menuList = menuList.filter(
        item => item.name !== "首页" && item.name !== "企管帮"
      );
      menuList.map((item, index) => {
        let subMenu =
          this.subMenu.find(
            subItem => subItem.devNo === item.no || subItem.proNo === item.no
          ) || {};
        subMenu = subMenu.child || [];
        this.$set(item, "child", subMenu);
      });

      if (menuList.length > 12) {
        let childlist = menuList.splice(12, menuList.length);
        let obj = {
          name: "更多",
          child: childlist ? childlist : []
        };
        menuList[12] = obj;
        menuList = menuList.splice(0, 13);
      } else {
        // menuList.push({ name: "更多" });
      }

      return menuList;
    },
    userInfo: function() {
      let user = this.$store.state.user;
      return user;
    },
    childMenuPos() {
      let len = this.childlist.length;
      let itemWidth = 152,
        width = 0,
        posdirection = "left",
        clientX = this.clientX;
      if (len > 3) {
        width = itemWidth * 3;
      } else {
        width = len * itemWidth;
      }
      if (clientX + width > 1200) {
        clientX = 0;
        posdirection = "right";
      }
      return {
        width,
        clientX,
        posdirection
      };
    }
  },
  methods: {
    boxHeight (array) {
      if (!array && !array.length) return '60px'
      // 一行三个，最多七行就出现滚动条, 最低60
      let maxRow = 7, itemHeight = 36, rowMax = 3
      let maxHeight = 60

      if (array.length < (maxRow * rowMax)) {
        maxRow = Math.ceil(array.length / rowMax)
      }

      maxHeight = maxRow * itemHeight

      return `${maxHeight - 16}px`
    },
    loginIndex() {
      let data = {
        callBack: function() {
          this.$store.dispatch("setUser");
        }.bind(this)
      };
      this.$_login(data);
    },
    moveNav() {
      if (!this.$store.state.loginType) {
        this.loginIndex();
      } else {
        this.moverNav = true;
      }
    },
    // 点击目录跳转至对应的页面
    linkGo(item) {
      const testUrl = env[process.env.environment].VUE_APP_HUIYUAN;
      if (!item.pcUrl) return;
      if (
        item.name == "企业专项" &&
        item.isLogin &&
        !this.$store.state.loginType
      ) {
        let data = {
          callBack: function() {
            this.$store.dispatch("setUser");
            // this.pushThis();
          }.bind(this)
        };
        this.$_login(data);
        return;
      }
      console.log(item.pcUrl.indexOf(testUrl));
      if (item.pcUrl.indexOf(testUrl) !== -1) {
        const { loginType } = this.$store.state; // 用户是否登录
        let { identityType, teamAuthStatus } = this.userInfo;
        teamAuthStatus = teamAuthStatus ? teamAuthStatus : 0;
        const isTeam = teamAuthStatus && +teamAuthStatus === 2;
        const isSinglePass = +identityType === 0; // 个人用户
        if (!loginType || isSinglePass || isTeam) {
          window.open(testUrl);
        } else {
          item.isSelf
            ? (window.location.href = item.pcUrl)
            : window.open(item.pcUrl);
        }
        return;
      }
      item.isSelf ? (window.location.href = item.pcUrl) : window.open(item.pcUrl);
    },
    go2menuUrl(item) {
      if (!item.pcUrl) return;
      item.isSelf ? (window.location.href = item.pcUrl) : window.open(item.pcUrl);
    },
    setChildList(e, item, index) {
      this.clientX = e.target.offsetLeft;
      this.$set(this, 'childlist', item.children || [])
      this.activeIndex = index;
      console.log('this.clientX:%s,this.activeIndex:%s',this.clientX, this.activeIndex)
      console.log('this.childlist', this.childlist)
    },
    navMouseleave () {
      this.activeIndex = -1;
      console.log('this.activeIndex', this.activeIndex)
    }
  },
  created() {
    try {
      // 头部菜单列表获取
      // let menu = await apiHome.getMenuList();
      // store.commit('SET_INDEXMENU', menu.data.data) // 设置顶部菜单
      this.$store.dispatch("setUserMenu");
      console.log("异步获取经营帮菜单");
      console.log("同步华为代码库")
    } catch (error) {
      // menuList = [];
    }
  }
};
