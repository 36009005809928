import env from "@/../env.js";
export default [
  {
    devNo: "Allmerchandise", //测试环境对应的no
    proNo: "a", //正式环境no
    name: "商品/材料",
    child: [
      {
        name: "商品大厅",
        pcUrl: `${
          env[process.env.environment].VUE_APP_MATERIALSURL
        }/engineeringList`,
        self: false
      },
      {
        name: "自营商品",
        pcUrl: `${
          env[process.env.environment].VUE_APP_MATERIALSURL
        }/ownBusiness`,
        self: false
      },
      {
        name: "需求大厅",
        pcUrl: `${
          env[process.env.environment].VUE_APP_MATERIALSURL
        }/demandHallList`,
        self: false
      },
      {
        name: "企业专项",
        pcUrl: `${
          env[process.env.environment].VUE_APP_MATERIALSURL
        }/CompanyEarmarked`,
        self: false,
        isLogin:true
      },
      {
        name: "拍卖大厅",
        pcUrl: `${
          env[process.env.environment].VUE_APP_MATERIALSURL
        }/auctionHall`,
        self: false
      },
      {
        name: "促销商品",
        pcUrl: `${
          env[process.env.environment].VUE_APP_MATERIALSURL
        }/promotionGoods`,
        self: false
      },
      {
        name: "供方集采",
        pcUrl: `${
          env[process.env.environment].VUE_APP_MATERIALSURL
        }/SupplierGroup`,
        self: false
      }
    ]
  },
  {
    devNo: "b",
    proNo: "b",
    name: "招标/竞价",
    child: [
      {
        name: "招标大厅",
        pcUrl: `${env[process.env.environment].VUE_APP_TENDERURL}/zb`,
        self: false
      },
      {
        name: "招标公告",
        pcUrl: `${env[process.env.environment].VUE_APP_TENDERURL}/zb-gg`,
        self: false
      },
      {
        name: "动态竞价",
        pcUrl: `${env[process.env.environment].VUE_APP_TENDERURL}/direct-jb`,
        self: false
      },
      {
        name: "拍卖大厅",
        pcUrl: `${env[process.env.environment].VUE_APP_TENDERURL}/auction`,
        self: false
      },
      {
        name: "招标预告",
        pcUrl: `${env[process.env.environment].VUE_APP_TENDERURL}/notice`,
        self: false
      },
      {
        name: "需求预算",
        pcUrl: `${
          env[process.env.environment].VUE_APP_TENDERURL
        }/need-search?type=rentOut`,
        self: false
      },
      {
        name: "招采企业",
        pcUrl: `${env[process.env.environment].VUE_APP_TENDERURL}/zb-qy`,
        self: false
      },
      {
        name: "中标公告",
        pcUrl: `${env[process.env.environment].VUE_APP_TENDERURL}/noticeType`,
        self: false
      },
      // {
      //   name: "招标信息服务",
      //   pcUrl: `${env[process.env.environment].VUE_APP_TENDERURL}/zb-infor`,
      //   self: false
      // },
      {
        name: "现场竞标",
        pcUrl: `${env[process.env.environment].VUE_APP_TENDERURL}/jb`,
        self: false
      }
    ]
  },
  {
    devNo: "jczx",
    proNo: "c",
    name: "集采/团购",
    child: [
      {
        name: "集采大厅",
        pcUrl: `${env[process.env.environment].VUE_APP_GROUNPBUYING}/jiCaiHall`,
        self: false
      },
      {
        name: "需方发起",
        pcUrl: `${
          env[process.env.environment].VUE_APP_GROUNPBUYING
        }/jiCaiLobby`,
        self: false
      },
      {
        name: "供方发起",
        pcUrl: `${env[process.env.environment].VUE_APP_GROUNPBUYING}/shopJiCai`,
        self: false
      },
      {
        name: "企业专项",
        pcUrl: `${
          env[process.env.environment].VUE_APP_GROUNPBUYING
        }/enterpriseSpecial`,
        self: false
      },
      {
        name: "即报即采",
        pcUrl: `${env[process.env.environment].VUE_APP_GROUNPBUYING}/fastJiCai`,
        self: false
      },
      {
        name: "汇集采购",
        pcUrl: `${
          env[process.env.environment].VUE_APP_GROUNPBUYING
        }/collectJiCai`,
        self: false
      }
    ]
  },
  {
    devNo: "d",
    proNo: "d",
    name: "租赁经营",
    child: [
      {
        name: "出租大厅",
        pcUrl: `${env[process.env.environment].VUE_APP_RENTALMARKET}/both`,
        self: false
      },
      {
        name: "需求大厅",
        pcUrl: `${env[process.env.environment].VUE_APP_RENTALMARKET}/demand`,
        self: false
      },
      {
        name: "自营租赁",
        pcUrl: `${env[process.env.environment].VUE_APP_RENTALMARKET}/bothSelf`,
        self: false
      },
      {
        name: "油品销售",
        pcUrl: `${
          env[process.env.environment].VUE_APP_MATERIALSURL
        }/engineeringList?c1=38`,
        self: false
      },
      {
        name: "地图查找",
        pcUrl: `${env[process.env.environment].VUE_APP_RENTALMARKET}/searchMap`,
        self: false
      },
      {
        name: "企业专项",
        pcUrl: `${env[process.env.environment].VUE_APP_RENTALMARKET}/theBusiness?isSpecial=1`,
        self: false
      }
    ]
  },
  {
    devNo: "e",
    proNo: "e",
    name: "劳务/工队",
    child: [
      {
        name: "工程工队",
        pcUrl: `${env[process.env.environment].VUE_APP_TEAM_URL}/teamList`,
        self: false
      },
      {
        name: "专业工人",
        pcUrl: `${env[process.env.environment].VUE_APP_TEAM_URL}/worker`,
        self: false
      },
      {
        name: "工种报价",
        pcUrl: `${
          env[process.env.environment].VUE_APP_TEAM_URL
        }/volunteerTeamOffer`,
        self: false
      },
      
      {
        name: "劳务用工",
        pcUrl: `${env[process.env.environment].VUE_APP_TEAM_URL}/labour`,
        self: false
      },
      {
        name: "临时用工",
        pcUrl: `${
          env[process.env.environment].VUE_APP_TEAM_URL
        }/FlexibleEmployment`,
        self: false
      },
      {
        name: "工程分包",
        pcUrl: `${
          env[process.env.environment].VUE_APP_TEAM_URL
        }/zb`,
        self: false
      },
      
      {
        name: "劳务公司",
        pcUrl: `${
          env[process.env.environment].VUE_APP_TEAM_URL
        }/LaborServiceCompany`,
        self: false
      },
      {
        name: "人气工队",
        pcUrl: `${
          env[process.env.environment].VUE_APP_TEAM_URL
        }/popularityTeam`,
        self: false
      }
    ]
  },
  {
    devNo: "wlys",
    proNo: "w",
    name: "物流/货运",
    child: [
      {
        name: "货运大厅",
        pcUrl: `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/trunk?active=1`,
        self: false
      },
      {
        name: "货车大厅",
        pcUrl: `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/trunk?active=2`,
        self: false
      },
      {
        name: "竞价货运",
        pcUrl: `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/trunk?active=1&childActive=1`,
        self: false
      },
      {
        name: "司机找货",
        pcUrl: `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/trunk?active=2&childActive=1`,
        self: false
      },
      {
        name: "企业专项",
        pcUrl: `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/trunk?active=1&childActive=3`,
        self: false
      },
      {
        name: "可视找货",
        pcUrl: `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/RegionalLogistics`,
        self: false
      },
      {
        name: "可视找车",
        pcUrl: `${
          env[process.env.environment].VUE_APP_LOGISTICSURL
        }/RegionalVehicle`,
        self: false
      }
    ]
  },
  {
    devNo: "vip",
    proNo: "hyjy",
    name: "客户管理",
    child: [
      {
        name: "会员管理", // https://vip.gcw.net/Classification
        pcUrl: `${env[process.env.environment].VUE_APP_HUIYUAN}/Classification`,
        self: false
      },
      {
        name: "会员经营",
        pcUrl: `${env[process.env.environment].VUE_APP_HUIYUAN}/customerList`,
        self: false
      },
      {
        name: "招采经营",
        pcUrl: `${env[process.env.environment].VUE_APP_HUIYUAN}/biddingList`,
        self: false
      },
      {
        name: "集采专项",
        pcUrl: `${env[process.env.environment].VUE_APP_HUIYUAN}/groupbuying`,
        self: false
      },
      {
        name: "会员商机",
        pcUrl: `${
          env[process.env.environment].VUE_APP_HUIYUAN
        }/membersBusinessOpportunities`,
        self: false
      },
      {
        name: "会员资产",
        pcUrl: `${env[process.env.environment].VUE_APP_HUIYUAN}/customerequity`,
        self: false
      },
      {
        name: "品牌分级",
        pcUrl: `${
          env[process.env.environment].VUE_APP_HUIYUAN
        }/brandClassification`,
        self: false
      },
      {
        name: "会员资质",
        pcUrl: `${env[process.env.environment].VUE_APP_HUIYUAN}/recruitersList`,
        self: false
      },
      {
        name: "会员业绩",
        pcUrl: `${
          env[process.env.environment].VUE_APP_HUIYUAN
        }/customerperformance`,
        self: false
      },
      {
        name: "会员动态",
        pcUrl: `${
          env[process.env.environment].VUE_APP_HUIYUAN
        }/customerdynamic`,
        self: false
      }
    ]
  },
  {
    devNo: "qiye",
    proNo: "qiye",
    name: "企业经营",
    child: [
      {
        name: "经营大厅",
        pcUrl: `${env[process.env.environment].qiye_RPOUTE_API}/businessHall`,
        self: false
      },
      {
        name: "企业需求",
        pcUrl: `${env[process.env.environment].qiye_RPOUTE_API}/MenuNeeds`,
        self: false
      },
      {
        name: "专项资质",
        pcUrl: `${
          env[process.env.environment].qiye_RPOUTE_API
        }/qualificationList`,
        self: false
      },
      {
        name: "知识产权",
        pcUrl: `${
          env[process.env.environment].qiye_RPOUTE_API
        }/intellectualProperty?tab=1`,
        self: false
      },
      {
        name: "专项资产",
        pcUrl: `${env[process.env.environment].qiye_RPOUTE_API}/assets`,
        self: false
      },
      {
        name: "荣誉荣耀",
        pcUrl: `${env[process.env.environment].qiye_RPOUTE_API}/honor`,
        self: false
      },
      {
        name: "经营动态",
        pcUrl: `${env[process.env.environment].qiye_RPOUTE_API}/dynamic`,
        self: false
      },
      {
        name: "企业查询",
        pcUrl: `${env[process.env.environment].qiye_RPOUTE_API}/mergMenu`,
        self: false
      },
      {
        name: "企业管理",
        pcUrl: `${env[process.env.environment].VUE_APP_QIGUANBANG}/index`,
        self: false
      }
    ]
  },
  {
    devNo: "szx",
    proNo: "szx",
    name: "食住行",
    child: [
      {
        name: "机票",
        pcUrl: `${env[process.env.environment].VUE_TRIP}/plane`,
        self: false
      },
      {
        name: "酒店",
        pcUrl: `${env[process.env.environment].VUE_TRIP}/hotel`,
        self: false
      }
    ]
  },
  {
    devNo: "qyfw",
    proNo: "team",
    name: "企业服务",
    child: [
      // {
      //   name: "转让汇集",
      //   pcUrl: `${
      //     env[process.env.environment].qiye_SERVICE
      //   }/collection`,
      //   self: false
      // },
      // {
      //   name: "专业报价",
      //   pcUrl: `${
      //     env[process.env.environment].qiye_SERVICE
      //   }/price`,
      //   self: false
      // },
      {
        name: "服务大厅",
        pcUrl: `${env[process.env.environment].qiye_SERVICE}/serviceFirm`,
        self: false
      },
      {
        name: "服务团队",
        pcUrl: `${env[process.env.environment].qiye_SERVICE}/serve`,
        self: false
      },
      {
        name: "需求大厅",
        pcUrl: `${env[process.env.environment].qiye_SERVICE}/demand`,
        self: false
      },
      // {
      //   name: "金融服务",
      //   pcUrl: `${env[process.env.environment].VUE_FINANCESERVICE}`,
      //   self: false
      // },
      {
        name: "知产交易",
        pcUrl: `${env[process.env.environment].qiye_SERVICE}/collection`,
        self: false
      },
      {
        name: "需求发布",
        pcUrl: `${env[process.env.environment].qiye_SERVICE}/demandSave`,
        self: false
      },
      
      // {
      //   name: "知识产权",
      //   pcUrl: `${
      //     env[process.env.environment].qiye_SERVICE
      //   }/knowledge`,
      //   self: false
      // },
      // {
      //   name: "人才招聘",
      //   pcUrl: `${
      //     env[process.env.environment].VUE_APP_RECRUIT
      //   }`,
      //   self: false
      // },
    ]
  },

  {
    devNo: "qygl",
    proNo: "qgb",
    name: "企管帮",
    child: []
  },
  {
    devNo: "h",
    proNo: "h",
    name: "分享",
    child: [
      {
        name: "行业分类",
        pcUrl: `${
          env[process.env.environment].VUE_APP_KNOWLEDGEsHARING
        }/course/list`,
        self: false
      },
      {
        name: "资讯动态",
        pcUrl: `${
          env[process.env.environment].VUE_APP_KNOWLEDGEsHARING
        }/pressList?type=8`,
        self: false
      }
      // {
      //   name: "个人中心",
      //   pcUrl: `${env[process.env.environment].VUE_APP_PERSONURL}/personalHomepage`,
      //   self: false
      // }
    ]
  },
  {
    devNo: "zsyz",
    proNo: "zsyz",
    name: "招商引资",
    child: [
      {
        name: "引资项目",
        pcUrl: `${
          env[process.env.environment].VUE_INVERTEMT
        }/investmentProject`,
        self: false
      },
      {
        name: "投资需求",
        pcUrl: `${env[process.env.environment].VUE_INVERTEMT}/investmentDemand`,
        self: false
      },
      {
        name: "产业园区",
        pcUrl: `${env[process.env.environment].VUE_INVERTEMT}/industrialPark`,
        self: false
      },
      {
        name: "优惠政策",
        pcUrl: `${
          env[process.env.environment].VUE_INVERTEMT
        }/preferentialPolicies`,
        self: false
      },
      {
        name: "招商宣传",
        pcUrl: `${
          env[process.env.environment].VUE_INVERTEMT
        }/investmentPublicity`,
        self: false
      },
      {
        name: "会议会展",
        pcUrl: `${
          env[process.env.environment].VUE_INVERTEMT
        }/conferenceExhibition`,
        self: false
      },
      {
        name: "政府网站",
        pcUrl: `${
          env[process.env.environment].VUE_INVERTEMT
        }/governmentWebsite`,
        self: false
      }
    ]
  },
  {
    devNo: "rczp",
    proNo: "rczp",
    name: "人才招聘",
    child: [
      {
        name: "职位大厅",
        pcUrl: `${
          env[process.env.environment].VUE_APP_RECRUIT
        }/positionHallList`,
        self: false
      },
      {
        name: "招聘企业",
        pcUrl: `${env[process.env.environment].VUE_APP_RECRUIT}/recruitersList`,
        self: false
      },
      {
        name: "高薪职位",
        pcUrl: `${
          env[process.env.environment].VUE_APP_RECRUIT
        }/highSalaryJobList`,
        self: false
      },
      {
        name: "求职大厅",
        pcUrl: `${
          env[process.env.environment].VUE_APP_RECRUIT
        }/recruitmentTeamList`,
        self: false
      }
    ]
  }
];
//
