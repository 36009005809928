//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
export default {
  name: "CitySelectCom",
  model: {
    prop: "modelVal", //指向props的参数名
    event: "change" //事件名称
  },
  data() {
    return {
      //全国数据
      AllCity:{
        name:"全国"
      },
      //是否没有数据
      isNotData: false,
      //是否是搜索
      isSearch: false,
      //输入值
      inputVal: "",
      //选中的类型
      activeName: "热门城市",
      //是否显示菜单
      isShowPopover: false,
      //子城市
      childCityList: [],
      //所有数组
      allCityList: [],
      //所有匹配的结果
      MatchList: [],
      timer: null,
      newCityList: []
    };
  },
  props: {
    modelVal: "",
    IsAll: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  watch: {
    newCityList: {
      handler: function(nVal) {
        // if (this.$store.state.newCityList.length) {
        //   let arr = this.$store.state.newCityList.slice(1);
        //   arr.forEach((item, index) => {
        //     item.children.forEach((itemIn, indexIn) => {
        //       this.allCityList.push(itemIn);
        //     });
        //   });
        //   this.handleClick({ name: this.activeName });
        // }
        if (nVal.length) {
          let arr = nVal.slice(1);
          arr.forEach((item, index) => {
            item.children.forEach((itemIn, indexIn) => {
              this.allCityList.push(itemIn);
            });
          });
          this.handleClick({ name: this.activeName });
        }
      },
      immediate: true
    },
    modelVal: {
      handler(nVal) {
        this.inputVal = nVal;
      },
      immediate: true
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.getNewCityList();
    });
  },
  methods: {
    //获取新的城市列表
    getNewCityList() {
      apiHome.getNewCityList().then(res => {
        if (res && res.status == 200) {
          this.newCityList = res.data;
        }
      });
    },
    handleClick(tab, event) {
      let child = this.newCityList.find((item, index) => {
        return item.name === tab.name;
      });
      this.childCityList = child.children;
    },
    //点击城市项
    clickItem(item) {
      this.inputVal = item.name;
      if (this.IsAll) {
        this.$emit("change", item.name);
      } else {
        this.$emit("change", item.no);
      }

      this.isShowPopover = false;
    },
    //点击候选项
    clickSearchItem(item) {
      this.inputVal = item.name;
      if (this.IsAll) {
        this.$emit("change", item.name);
      } else {
        this.$emit("change", item.no);
      }

      this.isShowPopover = false;
    },
    //输入框输入时
    inputContent() {
      if (!this.inputVal.length) {
        if (this.IsAll) {
          this.$emit("change", {});
        } else {
          this.$emit("change", "");
        }
        // this.$emit("change", "");
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.MatchList = [];
        this.isSearch = !!this.inputVal.length;
        let str = this.inputVal.toLocaleUpperCase();
        this.allCityList.forEach((item, index) => {
          if (item.searchKey.includes(str)) {
            this.MatchList.push(item);
          }
        });
        this.isNotData = !this.MatchList.length;
      }, 300);
    }
  },
  mixins: [],
  filters: {},
  components: {}
};
