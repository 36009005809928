//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

"use strict";
import link from "@/public/link.js";
import localStorage from "@/storage/localStorage.js";
import cookie from "@/storage/cookies.js";
import apiUser from "@/public/apiUser/apiUser";
import { httpMap } from "@/api/httpMapPositiom.js";
import { getPosition } from "@/pages/Home/components/goods/mapPosition.js";
import { getOS, getDownLoad } from "@/util/util.js";
export default {
  name: "Userinfo",
  data() {
    return {
      areaBox: false,
      colorClass: "",
      data1: [],
      shake: false,
      pushUserinfo: true,
      currentArea: {},
      showDownLoad: false,
      isShowCusServe: false,
      loadType: ''
    };
  },
  inject: ["reload"],
  components: {
    ChangeRole: () => import("./changRole"),
    DownloadApps: () => import("./downloadApps.vue")
  },
  methods: {
    // 创建链接下载对应版本app
    downApp() {
      // if (getDownLoad(getOS())) {
      //   let down = document.createElement("a");
      //   down.href = getDownLoad(getOS());
      //   down.click();
      // }
      this.loadType = 'pc'
      this.showDownLoad = true;
      // down.remove()
    },
    showArea() {
      // console.log('this.data1.length', this.data1.length)
      // return
      if (process.client) {
        if (this.data1.length === 0 || !this.data1.length) {
          // console.log('JSON.parse(localStorage.read("areaAll")' , JSON.parse(localStorage.read("areaAll")))
          // return
          this.data1 = JSON.parse(localStorage.read("areaAll"));
        }
        this.areaBox = true;
        this.shake = true;
      }
    },
    areaBoxHoverOut() {
      let that = this;
      this.shake = false;
      setTimeout(function() {
        if (!that.shake) {
          that.areaBox = false;
        }
      }, 50);
    },
    selectArea(val, index) {
      if (process.client) {
        this.colorClass = index;
        this.data1.forEach(item => {
          item.checked = false;
        });
        val.checked = true;
        this.areaBox = false;
        cookie.cookieSet("area", JSON.stringify(val));
        localStorage.set("areaAll", JSON.stringify(this.data1));
        this.currentArea = val;
        this.setLoacations(val); // 走后台数据返回的经纬度
      }
    },
    quit() {
      this.$confirm("确认退出？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(res => {
          this.$store.dispatch("zjkjIm/logout");
          this.$message.success("退出成功");
          this.$store.commit("LOGIN_OUT");
        })
        .catch(() => {});
    },
    pushThis() {
      let that = this;
      this.pushUserinfo = false;
      this.$nextTick(function() {
        that.pushUserinfo = true;
      });
    },
    loginIndex() {
      let data = {
        callBack: function() {
          this.$store.dispatch("setUser").then(async res => {
            let {
              identityType,
              realName,
            } = this.$store.state.user;
            const relevance = this.relevance
            console.log('relevance', relevance)
            const isRealPass = realName && realName.length > 0; // 实名认证
            // 2. 判断企业用户(如果是企业用户就已经实名认证了)
            const isRelevanceCom = +identityType === 1 && relevance; // 关联企业
            this.$store.dispatch("setUserCompany").then(async () => {
              let companyList = this.$store.state.CompanyOrganization.filter(
                item => +item.type == 1
              );
              if (!isRealPass) {
                // 未实名认证,1.未实名认证-->直接弹出所有框
                this.$_authUser();
              } else {
                // 实名认证, 2.已经实名，未加入企业或未认证企业弹--除去实名的弹框
                if (!isRelevanceCom && !companyList.length) {
                  this.$_authUser();
                }
              }
            });
          });
          // this.pushThis();
        }.bind(this)
      };
      this.$_login(data);
    },
    tosign() {
      link.sign();
    },
    toIndex() {
      link.index();
    },
    toPersonal() {
      // console.log(this.userInfo.id)
      if (this.userInfo.id) {
        link.personal();
      } else {
        this.loginIndex();
      }
    },
    toPage(e) {
      this.showDownLoad = true;
      this.loadType = 'app'
    },
    toMyMessage() {
      link.myMessage();
    },
    setLoacations(val) {
      // console.log(val, 'val-----');
      const { lng, lat, shortName } = val;
      const pos = {
        // ...res.data.geocodes[0],
        address: {
          province: shortName
        },
        lng,
        lat
      };

      localStorage.set("positionHome", JSON.stringify(pos));
      window.location.reload();
    },
    setLoacation(name) {
      httpMap.getPositionApi(name).then(res => {
        const { status } = res.data;
        if (+status === 1) {
          const location = res.data.geocodes[0].location.split(",");
          const pos = {
            ...res.data.geocodes[0],
            lng: +location[0],
            lat: +location[1]
          };
          localStorage.set("positionHome", JSON.stringify(pos));
        }
      });
    },
    async getAreaList() {
      const pos = JSON.parse(localStorage.read("positionHome")) || "";
      const ip_pos = localStorage.read("ipAddress") || "";
      const isUseCurrent =
        window.sessionStorage.getItem("isUseCurrent") || false; // 是否调用地图地位
      let mapAddr = null;
      let currentIpAddress =
        JSON.parse(window.sessionStorage.getItem("currentIpAddress")) || {};
      if (
        currentIpAddress.lat &&
        pos.address &&
        currentIpAddress.address.province.indexOf(pos.address.province) > -1
      ) {
        //为当前省
        this.$store.commit("SET_CURRENTIPADDRESS", true);
      } else {
        this.$store.commit("SET_CURRENTIPADDRESS", false);
      }
      if (!isUseCurrent) {
        // 初次进入调用地图地位
        mapAddr = await getPosition();
      } else {
        if (!pos.lng) {
          mapAddr = await getPosition();
        } else {
          mapAddr = pos;
        }
      }
      window.sessionStorage.setItem("isUseCurrent", true);
      const res = await apiUser.getArea();
      const { data, code, msg } = res.data;
      const currentArea = data.find(item => {
        if (item.areaName.indexOf(mapAddr.address.province.substr(0, 2)) > -1) {
          return item;
        }
      });
      console.log("currentArea", currentArea, mapAddr);
      const allArea = data.map(el => {
        return {
          ...el,
          checked: el.id == currentArea.id
        };
      });
      localStorage.set("areaAll", JSON.stringify(allArea));
      cookie.cookieSet("area", JSON.stringify(currentArea));
      this.data1 = allArea;
      this.currentArea = currentArea;
      localStorage.set("positionHome", JSON.stringify(mapAddr));
      if (pos) {
        // this.setLoacations(currentArea);
      }
      return currentArea;
    },
    go2Help(){
      if(this.$route.fullPath.indexOf('content') < 0){
        this.$router.push({ path: '/HelpCenter' })
      }
    }
  },
  watch: {},
  computed: {
    isLogin() {
      return this.$store.state.loginType;
    },
    userInfo() {
      return this.$store.state.user || {};
    },
    relevance(){
      return this.$store.state.relevance;
    },
    relevanceAdmin(){
      return this.$store.state.relevanceAdmin;
    }
  },
  mounted() {},
  created() {
    if (process.client) {
      // let hasReload = localStorage.read('hasReload')
      // if(!hasReload){
      //   localStorage.clear('', true)
      //   localStorage.set('hasReload', true)
      //    window.location.reload()
      // }
      this.getAreaList();
    }
  }
};
