//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AppConfig } from "@/config";
import axios from "@/http";
import sessionStorage from "@/storage/sessionStorage";
import link from "@/public/link.js";
import env from "../../../../env";
import { apiHome } from "@/api/apiHome";
import cookie from "@/storage/cookies.js";
import gif1 from "@/assets/public/gif-1.gif";
import localStorage from "@/storage/localStorage";
export default {
  name: "Search",
  data() {
    return {
      gif1,
      personal: env[process.env.environment].VUE_APP_PERSONURL,
      restaurants: [],
      isChecked: true,
      checkedNumber: 1,
      searchText: "",
      advertisement: {}
    };
  },
  components: {
    SubmitSearch: () => import("@/components/public/components/SubmitSearch")
  },
  methods: {
    //点击banner跳转
    jumpUrl(item) {
      item.url && window.open(item.url);
    },
    async getAdvertisement() {
      const currentArea = cookie.cookieRead("area");
      if(!currentArea) {
        setTimeout(() => {
          this.getAdvertisement()
        },500)
        return
      }
      let area = JSON.parse(currentArea);
      const {id} = area 
      const parmas = {
        type: 1,
        regionId: id
      }
      const res = await apiHome.getTopAdverList(parmas);
      const {code, data, msg} = res.data
      if(+code === 200) {
        this.advertisement = data
        return
      }
      this.$message.error(msg)
    },
    //点击logo, 刷洗页面
    refresh() {
      this.$refs.submitSearch.clearSearch()
      if (this.$route.path === "/") {
        window.location.reload();
      } else {
        this.$router.push("/");
      }
    },
    handleClick(command) {
      if (this.$store.state.user.userRole === 0) {
        window.location.href = `${this.personal}/authenticationManagement`;
      } else {
        if (command == "jixie") {
          let pageUrl = "/editRent";
          window.location.href = `${this.personal}${pageUrl}`;
        } else if (command == "shebei") {
          let pageUrl = "/equipmentRentalAdd";
          window.location.href = `${this.personal}${pageUrl}`;
        }
      }
    },
    loginIndex() {
      let data = {
        callBack: function() {
          this.$store.dispatch("setUser");
          this.pushThis();
        }.bind(this)
      };
      this.$_login(data);
    },
    // 快捷入口
    toCenterPage(pageUrl) {
      if (this.$store.state.user.userRole === 0) {
        window.location.href = `${this.personal}/authenticationManagement`;
      } else {
        window.location.href = `${this.personal}${pageUrl}`;
      }
    },
    equipmentRentalAdd() {
      link.equipmentRentalAdd();
    },
    switchoverIsChecked(bol) {
      if (!bol) {
        this.isChecked = false;
        this.checkedNumber = 2;
      } else {
        this.isChecked = true;
        this.checkedNumber = 1;
      }
      // if(et.target.innerText=='求租') {
      //   console.log(1)
      //   this.isChecked = false
      //   this.checkedNumber = 2
      // } else {
      //   console.log(2)
      //   this.isChecked = true
      //   this.checkedNumber = 1
      // }
    },
    handleCommand(command) {
      if (command === "goRentPublish") {
        this.$router.push({ path: `/userCenter/rentPublish` });
      } else {
        this.$router.push({ path: `/userCenter/inquiryPublish` });
      }
    },
    searcdClick() {
      if (this.searchText === "") {
        this.$message.error("搜索不能为空");
      } else {
        let that = this;
        if (this.checkedNumber === 1) {
          axios({
            method: "get",
            url:
              AppConfig.host +
              `/search/searchRentInfo?page=1&limit=10&words=` +
              that.searchText
          }).then(res => {
            sessionStorage.save(
              "storageSearchRentOut",
              JSON.stringify(res.data.data)
            );
            this.$router.push({ path: "/rentalInformationView/:id" });
            setTimeout(function() {
              window.location.reload();
            }, 100);
          });
        } else {
          axios({
            method: "get",
            url:
              AppConfig.host +
              `/search/searchWantedInfo?page=1&limit=10&words=` +
              that.searchText
          }).then(res => {
            sessionStorage.save(
              "storageSearchWanted",
              JSON.stringify(res.data.data)
            );
            this.$router.push({ path: "/leasedEnterprise/:id" });
            setTimeout(function() {
              window.location.reload();
            }, 100);
          });
        }
      }

      // this.$router.push({path:`rentOut`})
    },
    querySearch(queryString, cb) {
      // 获取智能搜索数据
      axios({
        method: "get",
        url:
          AppConfig.host +
          `/search/keywords?page=1&limit=10&words=` +
          queryString
      }).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          res.data.data[i].value = res.data.data[i].keyword;
        }
        cb(res.data.data);
      });
    },
    handleSelect(item) {},
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    }
  },
  watch: {},
  mounted() {
    this.getAdvertisement();
  },
  created() {}
};
