const setColumnDataColor = ({ row, column, rowIndex, columnIndex }) => {
  // if (column.property === 'title' || column.property === 'strongLevel' || column.property === 'cangchu_proper') {
  if (rowIndex === 0 && column.type != 'selection') {
    return 'color: #333333;padding: 16px 0;font-weight: 400;background-color:#FAFAFA;font-size:14px;font-weight: bold;height:54px'
  }
  if (column.type == 'selection') {
    return 'border-right:1px solid #E4E7ED;background-color:#FAFAFA;text-align: center'
  }
}
const setCellStyle = ({ row, column, rowIndex, columnIndex }) => {
  if (column.type != 'selection') {
    return {
      color: '#666666',
      // cursor: 'pointer',
      padding: '0',
      height: '54px'
    }
  }
  if (column.type == 'selection') {
    return 'border-right:1px solid #E4E7ED;text-align: center'
  }
}
export { setColumnDataColor, setCellStyle }
